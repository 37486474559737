<template>
  <div id="PovertyIndex">
    <!-- 查询 -->
    <div class="search-input-div">
      <van-search class="search-input" v-model="query.student" show-action placeholder="请输入学号或姓名">
        <template #action>
          <div @click="toQuery">查询
          </div>
        </template>
      </van-search>
    </div>
    <!-- 卡片数据 -->
    <van-list class="list" v-model="loading" :immediate-check="false" offset="30" :finished="finished" finished-text="没有更多了" @load="onLoad">
      <van-empty v-if="showEmpty" description="暂无数据" />
      <van-cell-group class="shadow" v-else inset style="margin-top:15px" v-for="(row,index)  in data" :key="index">
        <van-cell>
          <template #title>
            <div class="v-year">{{row.year+'年'}}</div>
            <div class="v-title">
              <span class="v-title-name" @click="toStuDeatil(row)">{{row.stuName}}</span>
              <span class="v-title-code" @click="toStuDeatil(row)">{{row.stuNo}}</span>
              <span class="v-title-icon" @click="toDetail(row)">
                <van-icon name="arrow" /></span>
            </div>
            <div class="custom-centent  hidden-text" style="width:95%">
              <svg-icon icon-class="date" size="15" /> 班级: {{row.className}}
            </div>
            <div class="custom-centent  hidden-text" style="width:95%">
              <svg-icon icon-class="date" size="15" /> 等级: {{row.rankName}}
            </div>
            <div class="custom-centent  hidden-text" style="width:95%">
              <svg-icon icon-class="date" size="15" /> 评议: {{row.reviewName}}
            </div>
          </template>
          <template #right-icon>
            <span v-if=" (row.spzt+'')==='9'" class="audit success-color b-color-success">审核成功</span>
            <span v-else-if="['1','2','3'].indexOf(row.spzt+'') >-1" class="audit warning-color b-color-warning">审核中</span>
            <span v-else-if="['101','201','301'].indexOf(row.spzt+'') >-1" class="audit error-color b-color-error">审核不通过</span>
            <span v-else class="audit info-color b-color-info">待提交</span>
            <div class="ckeckbox-st" @click="checkHandel(row)">
              <van-checkbox v-model="row.checked" icon-size="21px" shape="square"></van-checkbox>
            </div>
          </template>
        </van-cell>
        <van-cell>
          <template #title>
            <span v-if="statustag(row.spzt,'success')" class="success-color hidden-text95">
              <div class="status-tag ok-tag"></div>{{getSpztLabel(row.spzt)}}
            </span>
            <span v-else-if="statustag(row.spzt,'info')" class="info-color hidden-text95">
              <div class="status-tag info-tag"></div>{{getSpztLabel(row.spzt)}}
            </span>
            <span v-else-if="statustag(row.spzt,'error')" class="error-color hidden-text95 ">
              <div class="status-tag error-tag"></div>{{getSpztLabel(row.spzt)}}
            </span>
            <span v-else-if="statustag(row.spzt,'warning')" class="warning-color hidden-text95 ">
              <div class="status-tag warning-tag"></div>{{getSpztLabel(row.spzt)}}
            </span>
          </template>
          <template #right-icon>
            <van-button class="table-del-btn" :disabled="disableBtn(row.spzt,'del')" @click="toDel(row)" :loading="row.delLoading" plain round size="small" type="warning">删除</van-button>
            <van-button class="table-edit-btn" :disabled="disableBtn(row.spzt,'edit')" @click="toEdit(row)" plain round size="small" type="primary">编辑</van-button>
          </template>
        </van-cell>
      </van-cell-group>
    </van-list>
    <div class="batch-button">
      <van-row>
        <van-col span="5">
          <van-checkbox class="batch-checked" @click="selcetAll" v-model="checkedAll" checked-color="#23AB72">全选</van-checkbox>
        </van-col>
        <van-col span="9">
          <div class="batch-checked-text" v-html="selectionText"></div>
        </van-col>
        <van-col span="10">
          <van-button round block type="primary" color="#23AB72" :disabled="this.selections.length ===0" @click="batchAudit()">批量审批</van-button>
        </van-col>
      </van-row>
    </div>

    <van-dialog v-model="showAudit" title="审批" :showConfirmButton="false" width="90%">
      <div @click="toCloseAudit(false)" class="cancel">取消</div>
      <van-form ref="form">
        <van-field v-model="audit.appendData.field1" :border="true" label="认定等级" placeholder="选择认定等级" :rules="[{ required: true, message: '选择认定等级' }]">
          <template #input>
            <select class="select" @change="clickSelect($event,1)" v-model="dj1">
              <option value="0">请选择认定等级</option>
              <option v-for="item in dict.xj_pksrddj" :key="item.id" :value="item.value">{{item.label}}</option>
            </select>
          </template>
        </van-field>
        <van-field v-model="audit.appendData.reviewNo" label="民主评议" placeholder="请选择民主评议" :rules="[{ required: true, message: '请选择民主评议' }]">
          <template #input>
            <select class="select" @change="clickSelect($event,2)" v-model="dj2">
              <option value="0">请选择民主评议</option>
              <option v-for="item in dict.xj_mzpy" :key="item.id" :value="item.value">{{item.label}}</option>
            </select>
          </template>
        </van-field>
        <van-field v-model="audit.appendData.opinion" :border="true" rows="3" label="审核意见" type="textarea" maxlength="50" placeholder="请输入审核意见" show-word-limit :rules="[{ required: true, message: '请输入审核意见' }]" />
      </van-form>
      <van-row style="padding:10px 10px 20px 10px" justify="center">
        <van-col span="8" align="center">
          <van-button type="warning" :loading="auditLoading1" :disabled="auditDisabled1" round style="width:80%" @click="confirmAudit(8)">驳回</van-button>
        </van-col>
        <van-col span="8" align="center">
          <van-button type="danger" :loading="auditLoading2" :disabled="auditDisabled2" round style="width:80%" @click="confirmAudit(9)">拒绝</van-button>
        </van-col>
        <van-col span="8" align="center">
          <van-button type="primary" :loading="auditLoading3" :disabled="auditDisabled3" round style="width:80%" @click="confirmAudit(1)">同意</van-button>
        </van-col>
      </van-row>
    </van-dialog>
    <switch-role @change="toQuery()"></switch-role>
  </div>
</template>

<script>
import initData from "@/mixins/initData";
import batchAudit from "@/mixins/batchAudit";
import { mapGetters } from 'vuex'
import { del } from "@/api/modules/student/pksxx";


export default {
  name: "Poverty",
  mixins: [initData, batchAudit],
  dicts: ['xj_shzt', 'xj_pksrddj', 'xj_mzpy'],
  data() {
    return {
      audit: {
        appendData: {
          field1: "",
          reviewNo: "",
          opinion: "同意",
          field3: "",
        },
        coreKey: "",
        type: "JZGL",
        callback: "api/jz/pksxx/approve",
        mkCode: "JZ",
        coreCode: "PKSRD",
        status: 9,
      },
      dj1: "0",
      dj2: "0"
    };
  },
  computed: {
    ...mapGetters({
      user: 'user',
      roles: 'roles',
    }),
  },
  created() { },
  methods: {
    beforeInit() {
      this.url = "/api/jz/pksxx";
      this.sort = ["spzt,asc", "unitNo,asc"];
      // 设置审批字段
      this.spIdField = "spzt"
      // 初始化条件
      this.beforeRefresh()
      return true;
    },
    beforeRefresh() {
      let roles = this.user.roles.map((item) => {
        return item.dataScope
      })
      if (roles.indexOf('自身') > -1) {
        this.query.spzt = '1,2,9,101,201,301,108,208,308'
      }
      if (roles.indexOf('本级') > -1) {
        this.query.spzt = '2,3,9,201,301,208,308'
      }
      if (roles.indexOf('全部') > -1) {
        let roleName = this.user.roles.map((item) => {
          return item.name
        })
        if (roleName.indexOf('超级管理员') === -1) {
          this.query.spzt = '3,9,301,308'
        }
      }
    },
    clickSelect(event, type) {
      if (type === 1) {
        if (this.dj1 === '0') {
          this.audit.appendData.field1 = ''
        } else {
          this.audit.appendData.field1 = this.dj1
        }
      } else {
        if (this.dj2 === '0') {
          this.audit.appendData.reviewNo = ''
        } else {
          this.audit.appendData.reviewNo = this.dj2
        }
      }
    },
    toDetail(data) {
      this.$router.push({
        path: "/xs/a03/detail",
        query: data,
      });
    },
    // 编辑
    toEdit(data) {
      this.$router.push({
        path: "/xs/a03/form",
        query: { id: data.id },
      });
    },
    // 删除操作
    toDel(data) {
      this.$dialog
        .confirm({
          title: "提示",
          message: "你确定要删除该条数据吗？",
        })
        .then(() => {
          this.$set(data, "delLoading", true);
          del([data.id])
            .then((res) => {
              this.$notify({
                type: "success",
                message: "操作成功",
                duration: 2000,
              });
              this.init();
              this.$set(data, "delLoading", false);
            })
            .catch((e) => {
              this.$set(data, "delLoading", false);
            });
        })
        .catch(() => {
          this.$set(data, "delLoading", false);
        });
    },
    async confirmAudit(type) {
      let roles = this.user.roles.map((item) => {
        return item.dataScope
      })
      let value = []
      if (roles.indexOf('自身') > -1) {
        value = this.selections.filter((item) => {
          return ['0', '1', '101', '2'].indexOf(item.spzt) > -1
        })
        this.audit.appendData.field3 = 1
      }
      if (roles.indexOf('本级') > -1) {
        value = this.selections.filter((item) => {
          return ['2', '3', '201'].indexOf(item.spzt) > -1
        })
        this.audit.appendData.field3 = 2
      }
      if (roles.indexOf('全部') > -1) {
        value = this.selections.filter((item) => {
          return ['3', '9', '301'].indexOf(item.spzt) > -1
        })
        this.audit.appendData.field3 = 3
      }
      this.audit.status = type
      if (value.length > 0) {
        await this.$refs.form.validate().then(
          (res) => {
            this.loadingStatus(type)
            this.selections.forEach((element) => {
              this.audit.coreKey = element.id;
              this.auditApi(this.audit).then((resp) => {
                if (resp.success) {
                }
              });
            });

          },
          (err) => { }
        );
        await this.toCloseAudit(true);
        this.resetField();
      } else {
        this.$notify({
          type: "success",
          message: "请选择需要自己审核的数据",
          duration: 2000,
        });
      }
    },
    resetField() {
      this.audit = {
        appendData: {
          field1: "",
          reviewNo: "",
          opinion: "同意",
          field3: "",
        },
        coreKey: "",
        type: "JZGL",
        callback: "api/jz/pksxx/approve",
        mkCode: "JZ",
        coreCode: "PKSRD",
        status: 9,
      }
    },
  },
}
</script>
<style lang="scss" scoped>
@import '~@/assets/styles/teacher/poverty.scss';

::v-deep .van-checkbox__icon--checked .van-icon {
  color: #fff;
  background-color: #23ab72;
  border-color: #23ab72;
}
::v-deep.van-field__label {
  font-size: 15px !important;
}

.select {
  border: 0px #fff solid;
  width: 100%;
  height: 26px;
  background-color: #fff;
  font-size: 15px;
  color: #323233;
  margin-left: -8px;
}
.cancel {
  color: #999999;
  position: absolute;
  top: 20px;
  right: 30px;
}
</style>